import $ from "./plugins/jquery-3.3.1.min.js";
import PDFDocument from "./plugins/pdfkit.js";
import blobStream from "./plugins/blob-stream.js";
import {duty} from "./duty.js";

export var pex = {
		
	init: function () {
		
		pex.pdfSetVars();
				
		$("#pdf").on("click", function () {
			pex.pdfInit();
			pex.pdfText();
			pex.pdfChart();
			pex.pdfBlob();
			pex.pdfDescr();
			pex.pdfFinish();								
		});
	},
	
	pdfInit: function() {

		pex.pdfDoc = new PDFDocument({
			size: [595.27584,841.88952],
			margins : {
				top: 72, 
				bottom: 10,
				left: 34,
				right: 34
			},
			layout: 'portrait',
			info: {
				Title: 'Duty of Care Maturity Model', 
				Author: 'www.cinfo.ch', 
				Subject: '', 
				Keywords: 'Duty of Care Maturity Model, CINFO, EISF',
				CreationDate: 'DD/MM/YYYY',
				ModDate: 'DD/MM/YYYY'
			},
			bufferPages: true
		});
	
		pex.stream = pex.pdfDoc.pipe(blobStream());
		pex.rc = { x:297.63792, y:312.63792};
		
		for (var key in pex.embeddedData) {
		    if ((pex.embeddedData.hasOwnProperty(key)) && (key.match(/DINOT/))) {
		        pex.pdfDoc.registerFont(key, pex.embeddedData[key]);
		    }
		}
		
		pex.pdfDoc.switchToPage(0); 
		pex.pdfBase();	
		pex.pdfDoc.addPage();
		pex.pdfDoc.switchToPage(1); 
		pex.pdfBase();	
		pex.pdfDoc.switchToPage(0); 
	},
	
	
	pdfBase: function() {
		
		// background
			pex.pdfDoc.rect(0, 0, 595.27584, 50);
			pex.pdfDoc.fillColor("#777470", 1);
			pex.pdfDoc.fill();
			pex.pdfDoc.rect(0, 50, 595.27584, 700);
			pex.pdfDoc.fillColor("#F3F3F3", 1);
			pex.pdfDoc.fill();
			// header
			pex.pdfDoc.font("DINOT-Bold");
			pex.pdfDoc.fontSize(24);
			pex.pdfDoc.fillColor("#FFFFFF", 1);
			pex.pdfDoc.text('Duty of Care Maturity Model', 34, 10);
			pex.pdfDoc.image(pex.embeddedData["swiss"], 534.27584, 13, {width: 24});
			// footer
			pex.pdfDoc.font("DINOT-Regular");
			pex.pdfDoc.fillColor("#777470", 1);
			pex.pdfDoc.fontSize(8);
			pex.pdfDoc.text('In collaboration with the working group of the Swiss Security Network', 34, 768);
			pex.pdfDoc.image(pex.embeddedData["eisf"], (pex.marginRight - 80), 770, {width: 50});
			pex.pdfDoc.image(pex.embeddedData["cinfo"], (pex.marginRight + 30), 770, {width: 50});
			pex.pdfDoc.moveTo(pex.marginRight, 770);
			pex.pdfDoc.lineTo(pex.marginRight, 825);   
			pex.pdfDoc.strokeColor("#777470", 1);
			pex.pdfDoc.lineWidth(0.4);
			pex.pdfDoc.stroke();
						
	},
	
	pdfText: function() {
					
			pex.top = pex.marginTop - pex.lineHeight;

			// legend
			pex.pdfDoc.fillColor("#000000", 1);
			pex.pdfDoc.font("DINOT-Bold");
			pex.pdfDoc.font("DINOT-Light");
			pex.pdfDoc.text('www.vollprecht.com', pex.marginRight, pex.marginTop + pex.marginGap + (pex.lineHeight * 16));
			pex.pdfDoc.text('Request from ' + pex.date, pex.marginRight, 72);
								
			$(".duty").each(function(i) {	
				
				pex.left = pex.marginLeft;
				pex.top = pex.top + pex.lineHeight;
				
				if (i>2) {pex.left = pex.marginLeft + pex.columnWidth + pex.columnGap;	}
				if (i==3) {pex.top = pex.marginTop + (pex.lineHeight * 8);}
				
				pex.pdfDoc.rect(pex.left, pex.top + pex.lineHeight + 2, pex.columnWidth - 20, 3);
				pex.pdfDoc.fillColor(pex.dutyColor[i], 1);
				pex.pdfDoc.fill();
				pex.pdfDoc.rect(pex.left, pex.top + pex.lineHeight + 2, pex.columnWidth - 20, 3);
				pex.pdfDoc.fillColor("#FFFFFF", 0.4);
				pex.pdfDoc.fill();
				pex.pdfDoc.fillColor("#000000", 1);
				pex.pdfDoc.font("DINOT-Bold");
				pex.pdfDoc.text($(this).find("h3").text(), pex.left, pex.top, { width: pex.columnWidth, align: 'left' });
				
				pex.top = pex.top + pex.lineHeight + pex.marginGap;
									
				$(this).find(".topic--slider").each(function(j) {
					
					pex.pdfDoc.fontSize(8);
					var label = $(this).find("h4").text() + ": ";
					var labelWidth = pex.pdfDoc.widthOfString(label);
					pex.pdfDoc.fillColor("#777470", 1);
					pex.pdfDoc.fillColor("#575552", 1);
					pex.pdfDoc.font("DINOT-Regular");
					pex.pdfDoc.text(label , pex.left, pex.top, { width: pex.columnWidth, align: 'left' });
					
					pex.pdfDoc.fontSize(5.5);
					pex.pdfDoc.font("DINOT-Regular");
					var level = pex.level[parseInt($(this).attr("data-level"))];
					var levelWidth = pex.pdfDoc.widthOfString(level);
					
					var levelLeft = Math.max(100, labelWidth);
					
					pex.pdfDoc.roundedRect(pex.left + levelLeft + 3, pex.top + 1.5, levelWidth + 8, 7, 8);
					pex.pdfDoc.fillColor("#BB0099", 1);
// 					pex.pdfDoc.fillColor("#F3F3F3", 1);
					pex.pdfDoc.fill();

// 					pex.pdfDoc.lineWidth(0.3);
// 					pex.pdfDoc.strokeColor("#BB0099", 1);
// 					pex.pdfDoc.stroke();
					
					pex.pdfDoc.fillColor("#F3F3F3", 1);
// 					pex.pdfDoc.fillColor("#BB0099", 1);
					pex.pdfDoc.text(level, pex.left + levelLeft + 7, pex.top+1);
					pex.pdfDoc.fontSize(8);
					
					pex.top = pex.top + pex.lineHeight;
				});
								
			});
			
	},
	
	pdfChart: function() {		
		
			pex.pdfDoc.translate(pex.rc.x, pex.rc.y);
			pex.pdfDoc.scale(0.6);	
		
			$(".pdfArc").each(function(i) {
				pex.pdfDoc.path($(this).attr("d"));
				pex.pdfDoc.fillColor(pex.arcColor[i], 1);
				pex.pdfDoc.fill();
			});
			
			$(".pdflevel").each(function(i) {
				pex.pdfDoc.circle(0, 0, parseInt($(this).attr("r")));
				pex.pdfDoc.fillColor("#FFFFFF", 0.4);
				pex.pdfDoc.fill();
			});
			
			$(".pdfLine").each(function(i) {
				pex.pdfDoc.path($(this).attr("d"));
				pex.pdfDoc.lineWidth(0.5);
				pex.pdfDoc.strokeColor(pex.topicColor[i], 1);
				pex.pdfDoc.stroke();
			});
			
			pex.pdfDoc.fillColor("#575552", 1);
			pex.pdfDoc.font("DINOT-Medium");
			pex.pdfDoc.fontSize(13);
			pex.pdfDoc.text('Recruitment', -52, -310, {align: 'center', width: 120});
			pex.pdfDoc.text('Induction/Onboarding', 54, -308, {align: 'center', width: 120});
			pex.pdfDoc.text('Training', 138, -240, {align: 'center', width: 120});
			pex.pdfDoc.text('Risk\nAssessment  ', 232, -177, {align: 'center', width: 100});
			pex.pdfDoc.text('Pre-departure Briefings', 262, -96, {align: 'center', width: 120});
			pex.pdfDoc.text('Risk\nTreatment ', 270, 10, {align: 'center', width: 90});
			pex.pdfDoc.text('Pre-departure Measures', 252, 104, {align: 'center', width: 120});
			pex.pdfDoc.text('Insuring\nAgainst Risks ', 204, 184, {align: 'center', width: 120});
			pex.pdfDoc.text('Auditing', 90, 256, {align: 'center', width: 120});
			pex.pdfDoc.text('Information\nManagement ', 10, 282, {align: 'center', width: 120});
			pex.pdfDoc.text('Documentation', -108, 286, {align: 'center', width: 120});
			pex.pdfDoc.text('Crisis\nManagement  ', -244, 246, {align: 'center', width: 120});
			pex.pdfDoc.text('Travel\nDe-briefings', -320, 174, {align: 'center', width: 120});
			pex.pdfDoc.text('Complaints\nMechanisms ', -372, 96, {align: 'center', width: 120});
			pex.pdfDoc.text('Disciplinary Procedures ', -392, 0, {align: 'center', width: 120});
			pex.pdfDoc.text('Health And\nSafety  ', -372, -96, {align: 'center', width: 120});
			pex.pdfDoc.text(' Redress\nMeasures ', -346, -180, {align: 'center', width: 120});
			pex.pdfDoc.text(' Risk\nManagement ', -290, -255, {align: 'center', width: 120});
			pex.pdfDoc.text('Partnership\nArrangements ', -182, -312, {align: 'center', width: 120});
			
	},
	
	pdfBlob: function() {
		
			pex.pdfDoc.path($("#pdfBlob").attr("d"));
			pex.pdfDoc.fillColor("#BB0099", 0.5);
			pex.pdfDoc.fill();
			
			$(".pdfBlobCircle").each(function() {
				pex.pdfDoc.circle(parseFloat($(this).attr("cx")).toFixed(3), parseFloat($(this).attr("cy")).toFixed(3), 4);
				pex.pdfDoc.fillColor("#BB0099", 1);
				pex.pdfDoc.fill();
			});
	
	},
	
	pdfTextBold: function (txt,ind) {
		var bold = txt.split(/<\/?b>/);
		if (bold.length > 1) {
			var eo = (bold[0][0] == "<") ? "even" : "odd";
			for (var t=0, tt=bold.length; t<tt; t++) {
				pex.pdfDoc.font("DINOT-Regular");
				if (((eo == "even") && (t%2 == 0)) || ((eo == "odd") && (t%2 != 0))){
					pex.pdfDoc.font("DINOT-Medium");
				}
				if (t==0) {
					pex.pdfDoc.text(bold[t], pex.pdfDoc.x,  pex.pdfDoc.y, {width: pex.columnWidth, continued: true, indent: ind});
				} else if (t == tt-1) {
					pex.pdfDoc.text(bold[t], {width: pex.columnWidth, continued: false});
				} else {
					pex.pdfDoc.text(bold[t], {width: pex.columnWidth, continued: true});
				}
			}
			pex.pdfDoc.font("DINOT-Regular");
		} else {
			pex.pdfDoc.text(txt, pex.pdfDoc.x,  pex.pdfDoc.y, {width: pex.columnWidth, indent: ind});
		}		
	},
	
	pdfDescr: function() {
		
		pex.descr = [];
		
		$("#duty--wrapper .duty").each(function(i) {	
				
			$(this).find(".topic--slider").each(function(j) {
				var d = {},l;
				d.topic = $(this).find("h4").text();
				l = parseInt($(this).attr("data-level"));
				d.duty = $(this).parent().find("h3").text();
				d.level = pex.level[l];				
				d.text = duty[i][d.topic][l];
				d.text = d.text.replace(/(<br>)/g, "\n");
				pex.descr.push(d);							
			});
							
		});		
		
		pex.top = pex.marginLeft * 2;
		pex.left = pex.marginLeft;
		pex.pdfDoc.switchToPage(1); 
		pex.pdfDoc.font("DINOT-Bold");
		pex.pdfDoc.fillColor("#777470", 1);
		pex.pdfDoc.fontSize(12);
		pex.pdfDoc.text('Legend', pex.left, pex.top);
		pex.pdfDoc.fontSize(8);
		pex.pdfDoc.moveDown();
		pex.pdfDoc.x = pex.marginLeft;
		pex.pdfDoc.y = pex.marginLeft * 3;
		
		var x=0;
		var bottom=700;
		var header=0;
		
		pex.pdfDoc.font("DINOT-Bold");
		pex.pdfDoc.fillColor("#000000", 1);
		pex.pdfDoc.text(pex.descr[0].duty, pex.pdfDoc.x, pex.pdfDoc.y  + pex.lineHeight, {width: pex.columnWidth});
		pex.pdfDoc.rect(pex.pdfDoc.x, pex.pdfDoc.y + 2, pex.columnWidth - 20, 3);
		pex.pdfDoc.fillColor(pex.dutyColor[x], 1);
		pex.pdfDoc.fill();
		pex.pdfDoc.rect(pex.pdfDoc.x, pex.pdfDoc.y + 2, pex.columnWidth - 20, 3);
		pex.pdfDoc.fillColor("#FFFFFF", 0.4);
		pex.pdfDoc.fill();
		pex.pdfDoc.fillColor("#777470", 1);
		pex.pdfDoc.y = pex.pdfDoc.y + pex.lineHeight - 3;
		x = x+1;
		
		
		for (var i=0, ii=pex.descr.length; i < ii; i++) {
												
			if ((i > 0) && (pex.descr[i].duty != pex.descr[i-1].duty)) {
				if (pex.pdfDoc.y != pex.marginLeft * 3) {pex.pdfDoc.moveDown();}
				pex.pdfDoc.font("DINOT-Bold");
				pex.pdfDoc.fillColor("#000000", 1);
				pex.pdfDoc.text(pex.descr[i].duty, pex.pdfDoc.x, pex.pdfDoc.y  + pex.lineHeight, {width: pex.columnWidth});
				pex.pdfDoc.rect(pex.pdfDoc.x, pex.pdfDoc.y + 2, pex.columnWidth - 20, 3);
				pex.pdfDoc.fillColor(pex.dutyColor[x], 1);
				pex.pdfDoc.fill();
				pex.pdfDoc.rect(pex.pdfDoc.x, pex.pdfDoc.y + 2, pex.columnWidth - 20, 3);
				pex.pdfDoc.fillColor("#FFFFFF", 0.4);
				pex.pdfDoc.fill();
				pex.pdfDoc.fillColor("#777470", 1);
				pex.pdfDoc.y = pex.pdfDoc.y + pex.lineHeight - 3;
				x = x+1;
			}
			
			// Topic	
			pex.pdfDoc.fontSize(8);
			pex.pdfDoc.font("DINOT-Bold");
			pex.pdfDoc.text(pex.descr[i].topic, pex.pdfDoc.x, pex.pdfDoc.y + pex.lineHeight, {width: pex.columnWidth});
			// Level	
			pex.pdfDoc.fontSize(5.5);
			pex.pdfDoc.font("DINOT-Regular");
			pex.pdfDoc.roundedRect(pex.pdfDoc.x, pex.pdfDoc.y+1.5, pex.pdfDoc.widthOfString(pex.descr[i].level) + 8, 7, 8);
// 			pex.pdfDoc.lineWidth(0.3);
// 			pex.pdfDoc.strokeColor("#BB0099", 1);
// 			pex.pdfDoc.stroke();
			pex.pdfDoc.fillColor("#BB0099", 1);
			pex.pdfDoc.fill();
			pex.pdfDoc.fillColor("#F3F3F3", 1);
// 			pex.pdfDoc.fillColor("#BB0099", 1);
			pex.pdfDoc.y = pex.pdfDoc.y + 1;
			pex.pdfDoc.text(pex.descr[i].level, pex.pdfDoc.x + 4,  pex.pdfDoc.y, {width: pex.columnWidth});
			pex.pdfDoc.y = pex.pdfDoc.y + 1;
			pex.pdfDoc.x = pex.pdfDoc.x - 4;
			// Description			
			pex.pdfDoc.fontSize(8);
			pex.pdfDoc.font("DINOT-Regular");
			pex.pdfDoc.fillColor("#777470", 1);	
			
			if (pex.descr[i].text.match(/<ul>/) !== null ) {
				
				pex.descr[i].text = pex.descr[i].text.replace(/(<ul>)/g, "\n");
				pex.descr[i].text = pex.descr[i].text.replace(/(<\/ul>)/g, "");
				pex.descr[i].text = pex.descr[i].text.replace(/(<\/li>)/g, "\n");
				pex.descr[i].text = pex.descr[i].text.replace(/(<li>)/g, "<li>•  ");
				pex.descr[i].text = pex.descr[i].text.replace(/(\n\n)/g, "\n\n<li>");
				
				var list = pex.descr[i].text.split(/<li>/);
								
				for (var j=0, jj=list.length; j<jj; j++) {
					if (list[j][0] == "•") {
						pex.pdfDoc.x = pex.pdfDoc.x + 8.25;
						pex.pdfTextBold(list[j] , -8.25);
						pex.pdfDoc.x = pex.pdfDoc.x - 8.25;
					} else {
						pex.pdfTextBold(list[j],0);
					}
				}
				
			} else {
				pex.pdfTextBold(pex.descr[i].text,0);
			}
			
			if (i<ii-1) {
								
				header = (pex.descr[i].duty != pex.descr[i+1].duty) ? 120 : 0;
								
				if (pex.pdfDoc.heightOfString(pex.descr[i+1].text, {width: pex.columnWidth}) > (bottom - pex.pdfDoc.y - header)) {
					pex.pdfDoc.x = pex.pdfDoc.x + pex.columnWidth + pex.columnGap;
					pex.pdfDoc.y = pex.marginLeft * 3;
													
					if (pex.pdfDoc.x > (595-pex.columnWidth-34)) {
						pex.pdfDoc.addPage();
						pex.pdfBase();
						
						pex.pdfDoc.font("DINOT-Bold");
						pex.pdfDoc.fillColor("#777470", 1);
						pex.pdfDoc.fontSize(12);
						pex.pdfDoc.text('Legend', pex.left, pex.top);
						pex.pdfDoc.fontSize(8);
						pex.pdfDoc.moveDown();
						
						pex.pdfDoc.x = pex.marginLeft;
						pex.pdfDoc.y = pex.marginLeft * 3;
						
					}
				}
			}

		}
		
	},
	
	pdfFinish: function() {
		
		pex.pdfDoc.end();
			
		var saveData = (function () {
		    var a = document.createElement("a");
		    document.body.appendChild(a);
// 		    error old safari
// 		    a.style = "display: none";
		    return function (blob, fileName) {
		        var url = window.URL.createObjectURL(blob);
		        a.href = url;
		        a.download = fileName;
		        a.click();
		        setTimeout(function(){
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);  
				}, 1000);
		    };
		}());
		
		pex.stream.on('finish', function() {
		  var blob = pex.stream.toBlob('application/pdf');
		  saveData(blob, 'DocMaturityModel-' + pex.date.replace(/\./g, '') +'.pdf');
		});
			
	},
	
	pdfSetVars: function() {
		
		// https://stackoverflow.com/questions/55190357/pdfkit-browser-uncaught-referenceerror-fs-is-not-defined-when-using-custom						
		this.embeddedData = (function() {
			var dataCollection = {};
			function fetchData(name, src, type) {
				fetch(src)
				.then(
					function(response) {
						if (response.status !== 200) {
							console.log('Looks like there was a problem. Status Code: ' + response.status);
							return false;
						}
						response.arrayBuffer().then(function(data) {
							if (type === "ttf") {
								dataCollection[name] = new Uint8Array(data)
							} else {
								dataCollection[name] = data;
							}
							return;
						});
					}
				)
				.catch(function(err) {
					console.log('Fetch Error :-S', err);
					return false;
				});
			}
			fetchData("DINOT-Light", '/build/assets/scss/radar/fonts/DINOT-Light.ttf', 'ttf');
			fetchData("DINOT-Regular", '/build/assets/scss/radar/fonts/DINOT-Regular.ttf', 'ttf');
			fetchData("DINOT-Medium", '/build/assets/scss/radar/fonts/DINOT-Medium.ttf', 'ttf');
			fetchData("DINOT-Bold", '/build/assets/scss/radar/fonts/DINOT-Bold.ttf', 'ttf');
			fetchData('eisf', '/build/assets/img/eisf.jpg', 'jpg');
			fetchData('cinfo', '/build/assets/img/cinfo.jpg', 'jpg');
			fetchData('swiss', '/build/assets/img/swiss.jpg', 'jpg');
			return dataCollection;
		}());	
		
		this.marginRight = 470;
		this.marginLeft = 34;
		this.marginRight = 470;
		this.marginTop = 533;
		this.marginGap = 6;
		this.columnWidth = 164.3333;
		this.columnGap = 17;
		this.lineHeight = 11;
		this.top = this.marginTop - this.lineHeight;
		this.descr = [];
		
		this.date = (function() {
			var today = new Date();
			var dd = today.getDate();
			var mm = today.getMonth()+1;
			var yyyy = today.getFullYear();
			if(dd<10) dd='0'+dd;
			if(mm<10) mm='0'+mm;
			return (mm+'.'+dd+'.'+yyyy);
		}());
		
		this.level = ["Initial", "Structured", "Defined", "Measured", "Optimised"];
		this.dutyColor = ["#66CCFF", "#CCCC22", "#FFA000", "#AA5500"];			
		this.arcColor = ["#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#F3F3F3", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#F3F3F3", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#F3F3F3", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#F3F3F3"];
		this.topicColor = ["#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#CCCC22", "#CCCC22", "#CCCC22", "#FFA000", "#FFA000", "#FFA000", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500"];
		
	}
	
}	