////////////////////////////////////////////////////////////// 
////////////////////////// Data ////////////////////////////// 
////////////////////////////////////////////////////////////// 

export var duty = [{
	
		"Title": "Duty of information",
		"Description": "Collecting, collating, analysing, sharing, informing, understanding",
		"Level":[
			"Initial", 
			"Structured", 
			"Defined", 
			"Measured", 
			"Optimised"
		],
		"Process": [ 
			"Ad hoc and reactive implementation of DoC processes due to a lack of awareness of obligations.",
			"DoC obligations are acknowledged and identified resulting in processes being documented and therefore repeatable. Implementation needs improvement.",
			"DoC obligations are defined and integrated into related management processes thereby ensuring they are consistently followed.",
			"DoC compliance is quantitatively managed in accordance with agreed-upon metrics.",
			"DoC processes are consciously reviewed for continuous improvement at an organisation-wide level."
		],
		"Recruitment": [ 
			"Security and safety information is fed into the recruitment of new staff members on an ad hoc or reactive basis.",
			"Safety and security <b>information feeds into recruitment</b> based on the risk levels of locations and roles. <b>Risk assessments</b> for the context and candidate are carried out. Prospective candidates are provided with safety and security information relevant to the context and candidate.",
			"Safety and security information and risk assessement are <b>documented</b> and <b>systematically</b> fed into the recruitment process based on risk levels for locations and roles. <b>Consent</b> on security is included in the employment contract. Security training needs are assessed and fed into training methods. This includes:<ul><li>Carrying out a risk assessment before recruitment and again upon selection of a final candidate.</li><li>Providing security information to prospective candidates before recruitment.</li></ul><br>Security and safety input into the recruitment process can include, for example:<ul><li>Information on personal risk profiles.</li><li>Security and safety information in the job description and recruitment advertisement.</li><li>Security and safety questions in the interview questionnaire.</li></ul>",
			"Recruitment is <b>monitored</b> in order to <b>assess</b> the level of security and safety information provided during recruitment. <b>Non-compliance</b> with documented requirements is managed in accordance with consistently-applied, transparent and documented disciplinary procedures.",
			"Improvement is achieved through learnings from:<ul><li>internal and external incidents</li><li>other organisational processes (e.g., risk assessments)</li><li>staff consultation (recruiters and recruited)</li><li>expert review</li><li>peer learning/community of practice</li></ul><br>Feedback from recruited staff is systematically obtained and fed back to recruitment."
		],
		
		"Induction / Onboarding": [ 
			"Some form of induction received by most staff. This induction is more or less informal.",
			"New staff <b>receives essential information</b> and documentation after recruitment and prior to deployment. This includes:<ul><li>key policy documents related to duty of care</li><li>relevant procedures</li><li>code of conduct</li></ul>",
			"A <b>systematic</b> and <b>compulsory</b> induction of new staff is part of the onboarding after starting their function / before deployment. As part of the induction staff receive specific briefings to ensure their understanding of:<ul><li>key policies and/or regulations (e.g. Code of conduct, security, insurance, sexual harassment, mobbing, whistleblowing)</li><li>related procedures including local security plans</li><li>roles and responsibilities concerning duty of care as required</li><li>other key briefings related to the role</li></ul><br>The nature and content of the briefings are <b>defined</b> in accordance with the work country‘s risk level.</li>",
			"Induction is documented through:<ul><li>Attendance of staff to their induction briefings.</li><li>The provision of key documents.</li><li>Roles and responsibilities are communicated and clarified.</li></ul><br><b>Non-compliance</b> with documented requirements is managed in accordance with consistentlyapplied, transparent and documented disciplinary procedures.</li>",
			"Improvement is achieved through <b>learnings</b> from:<ul><li>internal and external incidents</li><li>risk assessment</li><li>staff consultation (recruiters and recruited)</li><li>expert review</li><li>peer learning/community of practice</li></ul><br>Feedback from staffs‘ induction is systematically obtained and fed back to the induction process.</li>"
		],
		"Training": [ 
			"There are some opportunities for staff to develop their personal capacity based on their interests in relation to their job.",
			"Training options are <b>available</b> to staff pertaining to:<ul><li>personal safety and security</li><li>their role as managers (SSRM and crisis management)</li></ul><br>Key competencies relating to duty of care are identified in organisational documentation.",
			"Staff are required to complete <b>training as per identified needs</b>, carried out by experts on key competencies in relation to:<ul><li>personal safety and security</li><li>their role as managers (SSRM and crisis management)</li><li>organisation-specific safety and security plans and procedures</li></ul><br>The process ensures that training needs are assessed and satisfied based on:<ul><li>staff members‘ personal risk profile</li><li>the work country‘s risk level</li></ul>",
			"Personal staff development is <b>documented</b> and failure to obtain identified key competencies within a specified period is <b>recorded</b>, and <b>redress measures</b> are taken.",
			"Feedback on training is regulary obtained from:<ul><li>trainers</li><li>trainees</li><li>experts</li><li>peers/community of practice</li></ul><br>This information is used to inform policy and future training. Identified key competencies relating to duty of care are regularly <b>re-assessed</b> and adapted to changing risks."
		],
		"Risk Assessment": [ 
			"Safety and security risk assessments are carried out in a reactive or ad hoc manner without a standardised template and used only at local level.",
			"There are <b>policies and plans in place</b>, which regulate safety and security risk assessements and associated responsibilities are clarified in job descriptions. There is a <b>defined template</b> for risk assessments.",
			"Safety and security risk assessment is <b>regularly updated</b> according to a context-specific frequency. The risk assessment includes the following outputs:<ul><li>understanding of threats and hazards (including physical and psychological ones)</li><li>the vulnerability of staff /assets to these threats and hazards</li><li>risk level <b>categorisation of locations</b> and activities</li></ul><br>Findings from risk assessments are <b>systematically</b> integrated into other management processes beyond security management, e.g. project cycle management, country strategy development, acquisition.",
			"Safety and security risk assessment otputs are <b>documented</b>. A system is in place to monitor that risk assessments are done/updated as prescribed. <b>Non-compliance</b> with documented requirements is managed in accordance with consistently-applied, transparent and documented disciplinary procedures.",
			"The safety and security risk assessment is regularly <b>reviewed</b> and <b>improved</b> by the management board with regards to:<ul><li>how it compares with peers</li><li>its adequacy for the organisation (activities, means)</li></ul><br><b>Feedback</b> from risk assessment is systematically obtained and used for impovement of induction"
		],
		"Pre-departure Briefings": [ 
			"Briefings are received upon request.",
			"Pre-departure briefings are <b>documented</b> in policies and security plans, and reflect the risk level of the location or role. A standard briefing template is provided.",
			"<b>All travelling staff receive pre-departure briefings</b> by the designated person in accordance with the risk level of the location and role. This information includes:<ul><li>safety and security risks (including personal risks due to profile)</li><li>safety and security risk treatment measures</li><li>staff safety and security roles and responsibilities (procedures to follow)</li><li>staff right to withdraw (informed consent)",
			"The provision of the briefing to travelling staff is <b>registered</b>, e.g. by staff acknowledging understanding of the content of the briefing in writing. Failure to obtain briefings in accordance with agreed-upon procedures is responded to in accordance with <b>consistently-applied, documented and transparent disciplinary procedures<b>.",
			"Information in briefings is <b>regularly updated</b> using information received from:<ul><li>peer learning /community of practice</li><li>risk assessments</li><li>post-deployment de-briefings</li><li>expert reviews</li></ul><br><b>Feedback</b> from pre-departure briefings is systematically obtained and fed back to the induction process."
		]
	},
	
	{
		"Title": "Duty of prevention",
		"Description": "Anticipating, planning, providing guidelines",
		"Level":[
			"Initial", 
			"Structured", 
			"Defined", 
			"Measured", 
			"Optimised"
		],
		"Process": [ 
			"Ad hoc and reactive implementation of DoC processes due to a lack of awareness of obligations.",
			"DoC obligations are acknowledged and identified resulting in processes being documented and therefore repeatable. Implementation needs improvement.",
			"DoC obligations are defined and integrated into related management processes thereby ensuring they are consistently followed.",
			"DoC compliance is quantitatively managed in accordance with agreed-upon metrics.",
			"DoC processes are consciously reviewed for continuous improvement at an organisation-wide level."
		],
		"Risk Treatment": [ 
			"Safety and security risk treatment is carried out in response to incidents rather than on the basis of proactive risk assessments.",
			"Safety and security risk treatment measures are <b>identified</b> and <b>documented</b> in policy and plans.",
			"Organisational risk threshold is identified. Safety and security risk treatment measures are <b>systematically implemented</b> based on the security risk assessment, including:<ul><li>prevention</li><li>mitigation</li><li>equipment</li><li>training, etc.</li></ul>",
			"Implementation of safety and security risk treatment measures is documented and monitored against an agreed organisational risk threshold (as documented in policy). Non-compliance is responded to via consistently-applied, documented and transparent disciplinary procedures.",
			"The effectiveness of safety and security risk treatment measures is regularly reviewed and improved. Through learnings from:<ul><li>internal and external incidents</li><li>other organisational processes (e.g., risk assessments)</li><li>staff feedback</li><li>expert review</li><li>peer learning/community of practice</li></ul>"
		],
		"Pre-departure Measures": [ 
			"There is no consistency in whether travellers receive medical (physical and mental) support before travel or not.",
			"Pre-departure measures are identified and <b>documented</b> based on risk assessment for destination and role. All staff are informed.",
			"Prior to departure travelling staff <b>confirm</b> to the designated person they implemented pre-depature measures. These measures include:<ul><li>health checks (mental and physical)</li><li>vaccinations</li><li>medication</li><li>personal safety and security competence</li><li>country risk-specific information</li></ul>",
			"Completion of pre-departure measures is documented and <b>registered</b>.<br><b>Failure</b> to complete all pre-departure measures is addressed in accordance with consistentlyapplied, documented and transparent disciplinary procedures.",
			"Improvement is achieved through learnings from reviews, which include:<ul><li>post-deployment de-briefings</li><li>other organisational processes (e.g., risk assessments)</li><li>peer learning</li><li>expert review</li></ul>"
		],
		"Insuring Against Risks": [ 
			"The organisation does not have comprehensive insurance coverage in place.",
			"Required personal insurance coverage is identified and <b>documented</b> based on risk assessment of locations and roles. Required organisational insurance coverage is identified and documented based on risk assessment of locations and roles.",
			"</b>Systematic</b> procedures are in place to ensure that all staff are insured against:<ul><li>health risks (as required)</li><li>liability risks (as required)</li></ul><br>The management takes systematic decisions on organisational insurance coverage based on identified risks.",
			"Insurance coverage is monitored by experts. Provision of insurance information to staff is <b>registered</b>. <b>Failure</b> to obtain insurance coverage as prescribed in policy and plans is responded to in accordance with consistently-applied, documented and transparent disciplinary procedures. (under- and overcoverage to be checked)",
			"Insurance policies and providers are assessed. <b>Improvement</b> is achieved through learnings from:<ul><li>internal and external incidents</li><li>risk assessements</li><li>staff consultation</li><li>expert reviews</li><li>peer learning /community of practice</li></ul>"
		]
	},
	
	{
		"Title": "Duty of monitoring",
		"Description": "Reviewing, checking compliance, learning",
		"Level":[
			"Initial", 
			"Structured", 
			"Defined", 
			"Measured", 
			"Optimised"
		],
		"Process": [ 
			"Ad hoc and reactive implementation of DoC processes due to a lack of awareness of obligations.",
			"DoC obligations are acknowledged and identified resulting in processes being documented and therefore repeatable. Implementation needs improvement.",
			"DoC obligations are defined and integrated into related management processes thereby ensuring they are consistently followed.",
			"DoC compliance is quantitatively managed in accordance with agreed-upon metrics.",
			"DoC processes are consciously reviewed for continuous improvement at an organisation-wide level."
		],
		"Auditing": [ 
			"The auditing of safety and security risk management in the organisation is ad hoc, reactive and not according to organisation-wide indicators.",
			"Safety and security risk management auditing is <b>documented</b>.",
			"The safety and security risk management system is regularly, <b>systematically and consistently audited</b> with regards to:<ul><li>risk assessment</li><li>risk treatment</li><li>risk monitoring</li></ul>",
			"Auditing is <b>documented</b> and carried out according to agreed-upon metrics. Key staff <b>oversee</b> the completion of the audit’s final improvement action plan. <b>Failure</b> to do so is addressed in accordance with consistently-applied, documented and transparent disciplinary procedures.",
			"Staff is informed about the outcome of audits. <b>Improvement</b> is achieved through <b>feedback</b> and learnings from audit outcomes, which include:<ul><li>comparision of audit results with peers and staff</li><li>internal and external incidents</li><li>risk assessments</li></ul>"
		],
		"Information Management": [ 
			"Safety and security incident data is captured in an inconsistent manner.",
			"Safety and security incident data are <b>documented</b> in a <b>standardised</b> way.",
			"Safety and security incident data is systematically:<ul><li><b>gathered</b></li><li><b>processed</b></li><li><b>analyzed</b></li></ul><br>to support the incident response management process, risk assessment and risk treatment including crisis management. Outputs are <b>systematically fed back</b> into local, national, regional /international level organisational learning and decision-making, e.g.:<ul><li>programming and reporting</li><li>safety and security procedures</li><li>advocacy/media response</li><li>HR</li><li>finance</li></ul><br>Designated staff are <b>systematically trained</b> in gathering, processing and analyzing incident information.",
			"Safety and security incident information management is <b>monitored and documented</b>. Failure to do so is addressed in accordance with consistently-applied, documented and transparent disciplinary procedures. Underreporting of incidents is addressed through traget-oriented measures, which include:<ul><li>awareness-raising</li><li>training</li></ul>",
			"<b>Improvement</b> is achieved through <b>feedback</b> and learnings about information management based on:<ul><li>quality and quantity of internal and external incident reporting</li><li>peer learning/community of practice</li><li>staff consultation and feedback on trainings in information management</li><li>expert reviews</li></ul><br>Learnings from incident reporting databases are regularly shared across departments and within management, where deemed appropriate."
		],
		"Documentation": [ 
			"There is no consistent documentation of safety and security risk -related information.",
			"Safety and security risk management related information is documented.",
			"Decisions and actions taken in relation to safety and security risk management are <b>systematically documented</b> at organizational level. These include:<ul><li>policies</li><li>plans</li><li>procedures</li><li>staff signature documenting informed consent processes and understanding staff conduct requirements outlined by policy.</li></ul><br>Documents related to safety and security risk management are <b>systematically archived</b>.",
			"Documentation processes is monitored. <b>Non-compliance</b> with documented requirements is managed in accordance with consistentlyapplied, transparent and documented disciplinary procedures.",
			"Documentation is <b>regularly reviewed</b> and amended. <b>Improvement</b> is achieved through learnings from:<ul><li>quality and quantity of internal and external documentation</li><li>peer learning/community of practice</li><li>staff consultation and feedback on documentation</li><li>expert reviews</li></ul>"
		]
	},
	
	{
		"Title": "Duty of intervention",
		"Description": "Responding, supporting, caring, protecting, ensuring compliance",
		"Level":[
			"Initial", 
			"Structured", 
			"Defined", 
			"Measured", 
			"Optimised"
		],
		"Process": [ 
			"Ad hoc and reactive implementation of DoC processes due to a lack of awareness of obligations.",
			"DoC obligations are acknowledged and identified resulting in processes being documented and therefore repeatable. Implementation needs improvement.",
			"DoC obligations are defined and integrated into related management processes thereby ensuring they are consistently followed.",
			"DoC compliance is quantitatively managed in accordance with agreed-upon metrics.",
			"DoC processes are consciously reviewed for continuous improvement at an organisation-wide level."
		],
		
		"Crisis Management": [ 
			"Management response to crises is ad hoc and reactive.",
			"Crisis response <b>manuals / tools</b> are elaborated in a) guidelines b) policies and c) approved plan that delineate a crisis management response structure.",
			"<b>Response procedures</b> responding and managing crises (internal and external) are documented and in accordance with prescribed <b>risk levels</b>. This process is supported by:<ul><li><b>regular</b> crisis management <b>training</b></li><li><b>pre-identified</b> and vetted crisis <b>assistance providers</b></li><li>investigation procedures</li><li>identification of qualified crisis management staff</li><li>consideration of staff needs, e.g. staff with disabilities</li></ul>",
			"<b>Monitoring</b> of the crisis management process implementation and preparation through:<ul><li>registering crisis management training <b>attendance</b></li><li>documentation and review of crisis management decision-making</li></ul><br><b>Non-compliance</b> with documented requirements is managed in accordance with consistently-applied, transparent and documented disciplinary procedures.",
			"Improvement is achieved through learning from crisis management experiences:<ul><li>staff consultation</li><li>a lessons learned exercise</li><li>peer learning</li><li>other organisational processes (e.g., risk treatment)</li><li>a review of crisis response providers</li></ul><br>A process is in place to regularly test the crisis response management structure, through:<ul><li>peer learning/community of practice</li><li>risk assessments</li><li>post-deployment de-briefings</li><li>expert reviews</li></ul><br>Feedback from crisis management responses is systematically fed back to the crisis management process."
		],
		
		"Travel De-briefings": [ 
			"Post-deployment/travel de-briefings are ad hoc and at the discretion of line managers.",
			"Post-deployment/travel de-briefings are <b>regular</b>.<b>Timing</b> is adequate, response structures defined and <b>availabe</b>.",
			"Post-deployment/travel de-briefings are <b>systematically integrated</b> into related management processes and undertaken in accordance with prescribed risk levels through:<ul><li>trip reports</li><li>face-to-face de-briefings with management and experts</li><li>provision of psycho-social support services</li></ul><br>And may be applicable for in-country and/or international travel in accordance with prescribed risk levels.",
			"<b>Monitor</b> post-deployment/travel de-briefings:<ul><li>Registering attendance at face-to-face de-briefings.</li></ul><br><b>Non-compliance</b> with documented requirements is managed in accordance with consistently-applied, transparent and documented disciplinary procedures.",
			"<b>Improvement</b> is achieved through learning from post-deployment experiences:<ul><li>staff consultation</li><li>a lessons learned exercise</li><li>peer learning</li><li>other organisational processes</li></ul><br><b>Feedback</b> from post-deployment is systematically fed back to the postdeployment process."
		],
		
		"Complaints Mechanism": [ 
			"The receipt of complaints is ad hoc and linked to awareness-raising activities. Responses to complaints is reactive and unstructured and dependent on management interest and capacity.",
			"<b>Mechanism</b> for receiving and addressing complaints is communicated and documented.",
			"Procedures for compaints and respond from internal and external individuals are <b>communicated, systematic and transparent</b>. It requires communication about and assurance that:<ul><li><b>Anonymous reporting</b> is guaranted and accessible through a variety of reporting mechanisms, e.g. online platform, email, letterbox.</li><li>Reponse is available in operational <b>languages</b>.</li></ul><br>This process is <b>integrated</b> into related management processes, including awareness raising activities within and outside of the organisation (e.g. training, induction, etc.). There is a process in place to <b>protect the identity</b> and well-being of reporters.",
			"<b>Monitoring</b> complaints / response mechanism in accordance with agreed-upon metrics. Have anonymised report list of complaints. <b>Documenting</b> of the security audit process or relevant staff performance reviews. <b>Non-compliance</b> with documented requirements is managed in accordance with consistently-applied, transparent and documented disciplinary procedures.",
			"The complaint procedures as well as response to complaints are <b>systematically reviewed</b>, for example, through a regular audit by experts. A process is in place to gather feedback on the complaints mechanism and amend processes accordingly.<ul><li>internal and external incidents</li><li>other organisational processes (e.g., risk assessments)</li><li>staff consultation</li><li>peer learning /community of practice.</li></ul><br><b>Feedback</b> from reviews are systematically fed back <b>operational management</b> and code of conduct."
		],
		
		"Disciplinary Procedures": [ 
			"The organisation becomes aware of infringements on a staff members‘ physical and mental wellbeing in an informal way or by chance. Perpetrators of such infringements are randomly held accountable, with some not held to account at all.",
			"A disciplinary/sanctions process is <b>documented</b> in policy and plans. This includes:<ul><li>Documenting <b>managers‘ responsibility and right</b> to take action to discipline or sanction staff for lack of compliance.</li></ul>",
			"Disciplinary procedures <b>are in place</b> that are consistently and transparently <b>applied</b> for non-compliance with documented requirements. This process includes:<ul><li>Staff and managers to have <b>formal opportunities</b> to discuss infringements against the physical and mental wellbeing of staff, e.g. in the annual appraisal process or through a whistleblowing mechanism.</li><li>Managers to know when and <b>how to escalate</b> reports on infringements to another level (both internally or externally).</li><li>Managers to be trained on <b>how to investigate</b> reports and <b>how to discipline</b> or sanction at their level.</li><li><b>Supervising managers</b> to take action when lower level managers fail to act in accordance with provisions.</li></ul><br>It is ensured that staff who report suspected non-compliance are not dicriminated against.",
			"The organisation <b>collects and analyses</b> data on allegations of infringements against the physical and mental wellbeing of staff. This includes how reported cases were handled by management.",
			"The disciplinary/sanctions policy and procedures are reglularly <b>reviewed and amended</b>. Such revision can be informed by, for example:<ul><li>a dedicated internal lessons learned exercise</li><li>e xternal expert review</li><li>r isk assessments followin internal or external incidents</li><li>s taff consultation</li><li>p eer learning/community of practice</li></ul><br>Staff and managers’ awareness of their rights and obligations in relation to compliance is regularly <b>assessed and improved</b>, including procedures for investigating allegations of infringements."
		],
		
		"Health And Safety": [ 
			"There is no consistent process for meeting site-related health and safety regulations.",
			"Health and safety regulations to meet reasonable standards in all the organisation’s facilities, including offices, accommodation and warehouses are <b>documented</b>. Staff care support is <b>available and documented</b> in policy or regulations in all countries in the form of services (internal or external) and/or trainings.",
			"Responsibilities for the implementation of health and safety regulations in all the organisation’s facilities are clearly <b>defined and reflected</b> in job-descriptions. Site-related health and safety considerations are <b>integral part</b> of relevant management processes, e.g. project management, budgeting. Risk management is integrated into related management processes, e.g. risk assessments. Staff care measures are <b>put in place</b> and are adequate. Staff’s wellbeing is systematically <b>assessed and acted upon</b> at the end of deployments or after serious incidents. Staff are <b>encouraged to attend</b> sessions or access services in a confidential manner and can do so without going through their line manager or other senior staff.",
			"Site health and safety measures are <b>systematically audited</b> according to transparent criteria. Staff’s wellbeing is <b>benchmarked</b> against acknowledged criteria, e.g. through regluar reports from the responsible for staff care or by means of a staff barometer. <b>Adjustments are made</b> on the basis of monitoring outcomes and <b>infringements addressed</b> in accordance with policy.",
			"The health and safety process is <b>regularly reviewed</b>, and learnings used to <b>adapt</b> the process for continuous improvement. This can be informed by, for example:<ul><li>analysis of incidents (internal or external)</li><li>staff consultation</li><li>expert review</li><li>peer learning/community of practice</li><li>legislative changes</li></ul>"
		],
		
		"Redress Measures": [ 
			"Staff access to redress measures is ad hoc and dependent on senior management interest.",
			"Redress measures are <b>documented</b> in policy or regulations allowing staff (or their next of kin) to ask for satisfaction of un-covered needs.",
			"Management at the appropriate level <b>receive information</b> on un-covered needs of staff (or their next of kin) having suffered a wrong at their workplace. Such needs may be e.g.:<ul><li>additional psychological support to staff or their next of kin</li><li>financial losses to staff or their next of kin</li><li>flexible return to work options</li><li>legal or administrative support</li></ul><br>The information is <b>formally acted</b> upon and the decision shared to the staff concerned (or their next of kin). The organisation has the <b>resources at hand</b> to provide redress measures, e.g. a special fund for extraordinary measures.</li></ul>",
			"The concerned staff (or their next of kin) have the possibility to <b>appeal</b> decisions taken in respect to redress measures concerning them.",
			"<b>Learnings</b> concerning systemically un-covered needs after critical incidents are gathered, for example, through information from, e.g.:<ul><li>de-briefings with affected staff about the incident</li><li>wider staff consultation</li><li>expert review</li><li>peer learning/community of practice</li><li>regular reporting from the responsible for staff care</li></ul><br>The coverage of needs arising from critical incidents is periodically reviewed and improved where feasible."
		],
		
		"Risk Management": [ 
			"Safety and security risk management roles and responsibilities are not well-informed and designated reactively.",
			"The safety and security risk management process is <b>documented</b> in policy or guidelines. The persons responsible for safety and security are <b>identified</b> and <b>communicated</b>.",
			"Safety and security risk management is <b>integrated</b> into relevant other management processes, for example, HR processes, project management, finance management, compliance, etc. Risk owners and risk managers are <b>defined</b> and their responsiblities and tasks reflected in job-descriptions. Expertise is <b>sought</b> where needed to duly inform safety and security risk management steps (assessment, treatment, monitoring, communicating). This includes expertise on crisis management.",
			"Safety and security risk management is periodically <b>audited</b> based on reckognised standards. Job descriptions are regularly <b>checked</b> against actual tasks and requirements.",
			"Safety and security risk management processes and roles are regularly <b>reviewed and updated</b> in accordance with learnings from, e.g.:<ul><li>staff consultation</li><li>expert review</li><li>peer learning/community of practice</li></ul>"
		],
		
		"Partnership Arrangements": [ 
			"Partnership arrangements are driven by programmatic and strategic demands and do not consider safety and security considerations.",
			"The way how safety and security risks are managed in partnership arrangements is <b>documented</b> in policy or regulations. This includes:<ul><li>the way to attribute roles and responsibilities of the partners in relation to safety and security</li><li>the way to attribute roles and responsibilities of the partners in relation to crisis management</li></ul>",
			"Due diligence checks on partner organisations are <b>carried out systematically</b> before entering into partnership agreements, this includes:<ul><li>the partner’s capacity to take care of their employees</li><li>the partner’s capacity to manage crisis</li></ul><br>Written partnership arrangements specify the partners’ roles and reponsibilities in relation to:<ul><li>safety and security</li><li>crisis management</li><li>capacity building (where deemed appropriate)</li></ul><br>This applies in particular to consortia arrangements and to seconded staff.",
			"Partnership arrangements are periodically <b>checked</b> for completeness in relation to:<ul><li>due diligence done</li><li>contractual specifications</li></ul><br>Failure to comply with this process is brought to the attention of senior management and <b>remedial measures</b> are taken.",
			"Due diligence processes and partnership arrangements are regularly <b>reviewed and assessed</b>. Learnings are acted upon and informed by, e.g.:<ul><li>analysis of incidents (internal or external)</li><li>staff consultation</li><li>expert review</li><li>peer learning/community of practice</li></ul>"
		]
	}
	
]