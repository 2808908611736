import $ from "./plugins/jquery-3.3.1.min.js";
import d3 from "./plugins/d3.min.js";
import noUiSlider from "./plugins/nouislider.min.js";
import {duty} from "./duty.js";
import {data} from "./data.js";
import {pex} from "./pex.js";

////////////////////////////////////////////////////////////// 
//////////////////////// Set-Up ////////////////////////////// 
////////////////////////////////////////////////////////////// 


var radar = {
	
	/* Radar chart design created by Nadieh Bremer - VisualCinnamon.com */
	init: function() {
		
		this.timer = 0;
		this.isMobile.init();
		this.isTablet.init();
		
		// generalBlob		
		this.maxValue = 4;
		this.levels = 4;
		this.blobColor = d3.scaleOrdinal().range(["#BB0099"]);
		this.dutyColor = d3.scaleOrdinal().range(["#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "none", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "#CCCC22", "none", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "#FFA000", "none", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "none"]);
		this.topicColor = d3.scaleOrdinal().range(	["#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#66CCFF", "#CCCC22", "#CCCC22", "#CCCC22", "#FFA000", "#FFA000", "#FFA000", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500", "#AA5500"]);
		this.opacityBlob = 0.5;
		this.strokeWidthBlob = 0;
		this.opacityScoreCircles = 0.4;
		this.maxValue = Math.max(4, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));
		this.allAxis = (data[0].map(function(i, j){return i.axis}));	
		this.total = this.allAxis.length;	 
		this.angleSlice = Math.PI * 2 / this.total;
		this.arcSlices = 3;
		this.aScale = d3.scaleLinear().range([0, 2 * Math.PI]).domain([0, (data[0].length * this.arcSlices)]);
		
		// desktopBlob	
		this.setDesktopVar();
		// mobileBlob		
		if (this.isMobile.flag) {
			this.setMobileVar();
		}
		// pdfBlob
		this.setPdfVar();
		
		// init Elements
		this.blob();
		this.topicSlider.init();		
		this.intro.init();
		pex.init();
		$("#form--trigger").on("click", function () {$("#help").fadeOut()});
		
	},
	
	setDesktopVar: function () {
		this.margin = {top: 140, right: 140, bottom: 180, left: 140};
		this.w = Math.max(360, (Math.min(800, window.innerWidth - 10) - this.margin.left - this.margin.right));
		this.h = Math.max(360, (Math.min(this.w, window.innerHeight - this.margin.top - this.margin.bottom - 20)));
		this.dotRadius = 5;	
		this.wrapWidth = 85;	
		this.minRadius = 40;
		this.maxRadius = Math.min(this.w/2, this.h/2);
		this.labelFactor = 1.25;	
		this.rScale = d3.scaleLinear().range([this.minRadius, this.maxRadius]).domain([0, this.maxValue]);
	},
	
	setMobileVar: function () {
		this.margin = {top: 10, right: 10, bottom: 10, left: 10};
		this.w = Math.max(300, (Math.min(375, window.innerWidth) - this.margin.left - this.margin.right));
		this.h = Math.max(300, (Math.min(this.w, window.innerHeight - this.margin.top - this.margin.bottom - 10)));	
		this.dotRadius = 3;	
		this.wrapWidth = 66;
		this.minRadius = 30;
		this.maxRadius = Math.min(this.w/3.2, this.h/3.2);
		this.labelFactor = 1.333;	
		this.rScale = d3.scaleLinear().range([this.minRadius, this.maxRadius]).domain([0, this.maxValue]);
	},
	
	setPdfVar: function () {
		this.pdfMinRadius = 40;
		this.pdfMaxRadius = 260;
		this.pdfRScale = d3.scaleLinear().range([this.pdfMinRadius, this.pdfMaxRadius]).domain([0, this.maxValue]);
		this.pdfChart("#pdfRadar");
		this.chart("#radar");
	},
	
	//
	// isMobile
	//
	isMobile: {
		init: function () {
			this.flag = false;
			if ($("#isMobile").css("display") == "none") {
				radar.isMobile.flag = true;
				radar.isTablet.flag = false;
				$("body").addClass("mobile").removeClass("tablet");
			}
		}
	},
	
	isTablet: {
		init: function () {
			this.flag = false;
			if ($("#isTablet").css("display") == "none") {
				radar.isMobile.flag = false;
				radar.isTablet.flag = true;
				$("body").addClass("tablet").removeClass("mobile");
			}
		}
	},
	//
	// intro
	//	
	intro: {
		
		init: function() {
			
			$(":not(.hide--intro) #enter").on("click", function() {
				$("body").addClass("hide--intro");
				
				var test = !radar.isMobile.flag && !radar.isTablet.flag;
				
				console.log(typeof test, test);
				
				if (test === true) {
					$('#form--trigger').prop('checked', true);
				} else {
					$('#form--trigger').prop('checked', false);
				}
			});
		}
	},
	
	//
	// topicSlider
	//
	topicSlider: {
		
		init: function() {
			
			radar.slider = $( ".topic--slider" );
			
			radar.slider.each( function(i) {
				noUiSlider.create(this, {
				    start: 0,
				    step: 1,
				    range: {
				        'min': 0,
				        'max': 4
				    },
				    format: {
					    to: function (value) {return parseInt(value);},
				        from: function (value) {return value;}
			        }
				});
			
				this.noUiSlider.on('change', function (e) { 
					$(this.target).attr( "data-level", e);
					radar.topicSlider.showLegend(i);
					radar.modifyData();
				});
				
				$(this).attr( "data-level", "0").append("<h4>"+data[0][i].axis+"</h4>");
	
			});
			
			$(".noUi-touch-area").on("mouseover", radar.topicSlider.showLegend);
			this.toggleLegend();			
		},
		
		toggleLegend: function() {
			$("#legend--wrapper .button").on("click", function() {
				$("body").toggleClass("show--legend");
			})
		},
		
		resumeIDS: function(id) {
			
			var IDS = [], dutyID, levelID, topicID;
							
			for(var i=0; i<data[0].length; i++) {
			    if (data[0][i]["id"]==id) { 
				    dutyID = data[0][i].duty;
				    levelID = duty[dutyID].Level[$("#topic"+id).attr( "data-level")];
				    topicID = Object.keys(duty[dutyID])[data[0][i].topic + 4];
				    break;
				}
			}
			
			IDS.id = id;
			IDS.duty = dutyID;
			IDS.level = levelID;
			IDS.topic = topicID
			return IDS;
			
		},
		
		showLegend: function(e) {
			
			var id = (typeof e == "object") ? (parseInt($(this).closest('.topic--slider').attr("id").match(/\d+/))) : (e);			
			var ids = radar.topicSlider.resumeIDS(id);

			$("#legend--wrapper h5").text(ids.topic);
			if ( !$("#legend--wrapper h6").length ) {
				$("#legend--wrapper h5").after("<h6></h6>");
			}
			$("#legend--wrapper h6").text(ids.level);
			$("#legend--wrapper p").html(duty[ids.duty][ids.topic][$("#topic"+ids.id).attr( "data-level")])
			
		}
		
	},
	
	pdfChart: function (id) {
		
		d3.select(id).selectAll("svg").remove();
		
		this.svg = d3.select(id).append("svg")
			.attr("width",  800)
			.attr("height", 800)
			.attr("id", "pdfRadarChart");
			
		this.g = this.svg.append("g").attr("transform", "translate(400, 400)");
		
		//TOPICS - ARCS
		this.pdfArcGrid = this.g.append("g").attr("class", "pdfArcWrapper").style("opacity", 0);

		this.pdfArc = d3.arc()
		    .innerRadius(this.pdfMinRadius)
		    .outerRadius(this.pdfMaxRadius)
		    .startAngle(function(d,i) { return this.aScale(d-1) })
		    .endAngle(function(d,i) { return this.aScale(d) });

		this.pdfArcWrapper = this.pdfArcGrid.selectAll(".pdfArc")
			.data(d3.range(0, (data[0].length) * radar.arcSlices) )
			.enter()
			.append("path")
			.attr("class", "pdfArc")
			.attr("d", function(d,i) { return radar.pdfArc(d); })
			.style("fill", function(d,i) { return radar.dutyColor(i); })
			.style("fill-opacity", 1)
			.style("stroke-width", 0.3)
			.style("stroke", function(d,i) { return radar.dutyColor(i); });
			
		//LEVELS	- CIRCLES
		this.pdfLevelGrid = this.g.append("g").attr("class", "pdfLevelWrapper");
		
		this.pdfLevelWrapper = this.pdfLevelGrid.selectAll(".pdflevel")
			.data(d3.range(0,(this.levels+1)).reverse())
			.enter()
			.append("circle")
			.attr("class", "pdflevel")
			.attr("r", function(d, i){return ((radar.pdfMaxRadius-radar.pdfMinRadius)/radar.levels*d)+radar.pdfMinRadius;})
			.style("fill", "#FFFFFF")
			.style("stroke", "none")
			.style("fill-opacity", this.opacityScoreCircles);

		//TOPIC-AXES
		this.pdfAxisGrid = this.g.append("g").attr("class", "pdfAxisWrapper");

		this.pdfAxis = this.pdfAxisGrid.selectAll(".pdfAxis")
			.data(this.allAxis)
			.enter()
			.append("g")
			.attr("class", "pdfAxis");
			
		this.pdfAxis.append("path")
			.attr("d", function(d, i){ 
				var x1 = radar.pdfMinRadius * Math.cos(radar.angleSlice*i - Math.PI/2);
				var y1 = radar.pdfMinRadius * Math.sin(radar.angleSlice*i - Math.PI/2);
				var x2 = radar.pdfRScale(radar.maxValue) * Math.cos(radar.angleSlice*i - Math.PI/2);
				var y2 = radar.pdfRScale(radar.maxValue) * Math.sin(radar.angleSlice*i - Math.PI/2);
				return "M" +  x1 + " " + y1 + " L" + x2 + " " + y2})
			.attr("class", "pdfLine")
			.attr("id", function(d,i) { return "pdfAxis"+i})
			.style("stroke", function(d,i) { return radar.topicColor(i); })
			.style("stroke-width", "0.5px");
		
	},
	//
	// chart
	//	
	chart: function(id) {
		
		d3.select(id).selectAll("svg").remove();
	
		this.svg = d3.select(id).append("svg")
			.attr("width",  this.w + this.margin.left + this.margin.right)
			.attr("height", this.h + this.margin.top + this.margin.bottom)
			.attr("id", "radarChart");
		
		this.g = this.svg.append("g").attr("transform", "translate(" + (this.w/2 + this.margin.left) + "," + (this.h/2 + this.margin.top) + ")");
		
		//TOPICS - ARCS
		this.arcGrid = this.g.append("g").attr("class", "arcWrapper");
				    
		this.arc = d3.arc()
		    .innerRadius(this.minRadius)
		    .outerRadius(this.maxRadius)
		    .startAngle(function(d,i) { return this.aScale(d-1) })
		    .endAngle(function(d,i) { return this.aScale(d) });
		    
		this.arcWrapper = this.arcGrid.selectAll(".arc")
			.data(d3.range(0, (data[0].length) * radar.arcSlices) )
			.enter()
			.append("path")
			.attr("class", "arc")
			.attr("d", function(d,i) { return radar.arc(d); })
			.style("fill", function(d,i) { return radar.dutyColor(i); })
			.style("fill-opacity", 1)
			.style("stroke-width", 0.3)
			.style("stroke", function(d,i) { return radar.dutyColor(i); });
		
		//LEVELS	- CIRCLES
		this.levelGrid = this.g.append("g").attr("class", "levelWrapper");
		
		this.levelWrapper = this.levelGrid.selectAll(".level")
			.data(d3.range(0,(this.levels+1)).reverse())
			.enter()
			.append("circle")
			.attr("class", "level")
			.attr("r", function(d, i){return ((radar.maxRadius-radar.minRadius)/radar.levels*d)+radar.minRadius;})
			.style("fill", "#FFFFFF")
			.style("stroke", "none")
			.style("fill-opacity", this.opacityScoreCircles);
			
		//TOPIC-AXES & LABELS
		this.axisGrid = this.g.append("g").attr("class", "axisWrapper");

		this.axis = this.axisGrid.selectAll(".axis")
			.data(this.allAxis)
			.enter()
			.append("g")
			.attr("class", "axis");
			
		this.axis.append("path")
			.attr("d", function(d, i){ 
				var x1 = radar.minRadius * Math.cos(radar.angleSlice*i - Math.PI/2);
				var y1 = radar.minRadius * Math.sin(radar.angleSlice*i - Math.PI/2);
				var x2 = radar.rScale(radar.maxValue) * Math.cos(radar.angleSlice*i - Math.PI/2);
				var y2 = radar.rScale(radar.maxValue) * Math.sin(radar.angleSlice*i - Math.PI/2);
				return "M" +  x1 + " " + y1 + " L" + x2 + " " + y2})
			.attr("class", "line")
			.attr("id", function(d,i) { return "axis"+i})
			.style("stroke", function(d,i) { return radar.topicColor(i); })
			.style("stroke-width", "0.5px");

		this.axis.append("text")
			.attr("class", "legend")
			.attr("text-anchor", "middle")
			.attr("dy", "0.35em")
			.attr("x", function(d, i){ return radar.rScale(radar.maxValue * radar.labelFactor) * Math.cos(radar.angleSlice*i - Math.PI/2); })
			.attr("y", function(d, i){ return radar.rScale(radar.maxValue * radar.labelFactor) * Math.sin(radar.angleSlice*i - Math.PI/2); })
			.text(function(d){return d})
			.call(radar.wrap, this.wrapWidth);
	},
	
	blob: function() {
		
		// PDF BLOB BEFORE VISIBLE BLOB > VISIBLE BLOB ON TOP
		// The pdf radial line function
		this.pdfRadarLine = d3.radialLine()
			.curve(d3.curveCardinalClosed.tension(0.66))
			.radius(function(d) { return radar.pdfRScale(d.value); })
			.angle(function(d,i) { return i*radar.angleSlice; });
			
		//Create wrapper for pdfBlob	
		this.pdfBlobWrapper = this.g.selectAll(".pdfBlobWrapper")
			.data(data)
			.enter().append("g")
			.attr("class", "pdfBlobWrapper");
		
		//Append pdfBlob	
		this.pdfBlobWrapper
			.append("path")
			.attr("id", "pdfBlob")
			.attr("d", function(d,i) { return radar.pdfRadarLine(d); })
			.style("fill", "none")
			.style("fill-opacity", 0)
			.exit().remove();
		
		this.pdfBlobWrapper.select("#pdfBlob").exit().remove();
				
		//Append circles of pdfBlob
		this.pdfBlobWrapper.selectAll(".pdfBlobCircle")
			.data(function(d,i) { return d; })
			.enter()
			.append("circle")
			.attr("class", "pdfBlobCircle")
			.attr("r", this.dotRadius)
			.attr("cx", function(d,i){ return radar.pdfRScale(d.value) * Math.cos(radar.angleSlice*i - Math.PI/2); })
			.attr("cy", function(d,i){ return radar.pdfRScale(d.value) * Math.sin(radar.angleSlice*i - Math.PI/2); })
			.style("fill", "none")
			.style("fill-opacity", 0)
			
		this.pdfBlobWrapper.exit().remove();	
		this.pdfBlobWrapper.selectAll(".blobCircle").exit().remove();
			
		// VISIBLE BLOB
		//The radial line function
		this.radarLine = d3.radialLine()
			.curve(d3.curveCardinalClosed.tension(0.66))
			.radius(function(d) { return radar.rScale(d.value); })
			.angle(function(d,i) { return i*radar.angleSlice; });
			
		this.blobWrapper = this.g.selectAll(".blobWrapper")
			.data(data)
			.enter().append("g")
			.attr("class", "blobWrapper");
				
		//Append blob	
		this.blobWrapper
			.append("path")
			.attr("id", "blob")
			.attr("d", function(d,i) { return radar.radarLine(d); })
			.style("fill", function(d,i) { return radar.blobColor(i); })
			.style("fill-opacity", this.opacityBlob)
			.exit().remove();
			
		this.blobWrapper.select("#blob").exit().remove();
		
		// get closest Point
		this.closestPoint = function (pathNode, point) {
			var pathLength = pathNode.getTotalLength(),
				pathPoints = [],
				slice = 4,
				d,
				best,
				bestLength,
				bestDistance = Infinity,
				bestLevel;
			
			for (var s=0; s<=slice; s++) {
				pathPoints.push(pathNode.getPointAtLength((pathLength/slice) * s)); 
				d = distance2(pathPoints[s]);

				if (d < bestDistance) {
					bestDistance = d;
					best = pathPoints[s];
					bestLevel = s;
				}
			}
			
			best = [best.x, best.y];
			best.distance = Math.sqrt(bestDistance);
			best.level = bestLevel;
			return best;
			
			function distance2(p) {
				var dx = p.x - point[0],
					dy = p.y - point[1];
				return dx * dx + dy * dy;
			}
		}
		
		this.dragged = function (d) {
			var m = d3.mouse(this),
				axis = $(this).attr("data-axis"),
			    path = d3.select("#axis"+axis),
			    pt = radar.closestPoint(path.node(), m),
			    slider = radar.slider.get(axis),
			    newX =  parseFloat(d3.select(this).attr('cx')),
				newY =  parseFloat(d3.select(this).attr('cy')) - 20,
			    ids = radar.topicSlider.resumeIDS(axis),
				p = this.getBoundingClientRect();
							    
		    d3.select(this).classed("selected", true);
			radar.tooltip
				.text(ids.level)
				.addClass('active')
				.offset({
					top: p.y + 20,
					left: p.x + 20
				});
			    
			if ($(slider).attr("data-level") != pt.level) {
				slider.noUiSlider.set(pt.level);
				$(slider).attr( "data-level", pt.level);
				radar.topicSlider.showLegend(axis);
				radar.modifyData();
			}
			
		}	
		
		this.dragend = function (d) {
			d3.select(this).classed("selected", false);
			radar.tooltip.removeClass('active');
		}
		
		this.mouseover = function (d) {
			
			var axis = $(this).attr("data-axis"),
				ids = radar.topicSlider.resumeIDS(axis),
				p = this.getBoundingClientRect();
				
			d3.select(this).classed("selected", true);
			radar.topicSlider.showLegend(axis);
			radar.tooltip
				.text(ids.level)
				.addClass('active')
				.offset({
					top: p.y + 20,
					left: p.x + 20
				});
		}	
		
		this.mouseout = function (d) {
			d3.select(this).classed("selected", false);
			radar.tooltip.removeClass('active');
		}	
							
		// VISIBLE BLOBS	
		//Append circles of blob
		this.blobWrapper.selectAll(".blobCircle")
			.data(function(d,i) { return d; })
			.enter()
			.append("circle")
			.attr("class", "blobCircle")
			.attr("data-axis", function(d,i) { return i})
			.attr("r", this.dotRadius)
			.attr("cx", function(d,i){ return radar.rScale(d.value) * Math.cos(radar.angleSlice*i - Math.PI/2); })
			.attr("cy", function(d,i){ return radar.rScale(d.value) * Math.sin(radar.angleSlice*i - Math.PI/2); })
			.call(d3.drag()
				.on("drag", radar.dragged)
				.on("end", radar.dragend))
			.on("mouseover", radar.mouseover)
			.on("mouseout", radar.mouseout);
			
		if (!this.tooltip) {
			$('#radar').append("<div id='tooltip'></div>");
			this.tooltip = $("#tooltip");
		}
						
		this.blobWrapper.exit().remove();	
		this.blobWrapper.selectAll(".blobCircle").exit().remove();
		
	},
	
	updateBlob: function(updateData) {
		
		// #pdfBlobWrapper	
		radar.pdfBlobWrapper
			.data(updateData)
						
		radar.pdfBlobWrapper.select("#pdfBlob")
			.transition()
			.attr("d", function(d,i) { return radar.pdfRadarLine(d); })
			
	    radar.pdfBlobWrapper.selectAll(".pdfBlobCircle")
			.data(function(d,i) { return d; })
			.each(function(d, j) {
				d3.select(this).transition()
					.attr("cx", function(d,i){ return radar.pdfRScale(d.value) * Math.cos(radar.angleSlice*j - Math.PI/2); })
					.attr("cy", function(d,i){ return radar.pdfRScale(d.value) * Math.sin(radar.angleSlice*j - Math.PI/2); })
		    });
		
		// #pdfBlob		
		radar.blobWrapper
			.data(updateData)
		
		radar.blobWrapper.select("#blob")
			.transition()
			.attr("d", function(d,i) { return radar.radarLine(d); })
		
		radar.pdfBlobWrapper
			.data(updateData)

		radar.blobWrapper.selectAll(".blobCircle")
			.data(function(d,i) { return d; })
			.each(function(d, j) {
				d3.select(this).transition()
					.attr("cx", function(d,i){ return radar.rScale(d.value) * Math.cos(radar.angleSlice*j - Math.PI/2); })
					.attr("cy", function(d,i){ return radar.rScale(d.value) * Math.sin(radar.angleSlice*j - Math.PI/2); })
		    });
	},
	
	modifyData: function() {
		
		this.slider = $(".topic--slider");
		this.slider.each(function() {
			
			var id = parseInt($(this).attr("id").match(/\d+/));
			var val = parseInt($(this).attr("data-level"));
						
			for(var i=0; i<data[0].length; i++) {
			    if (data[0][i]["id"]==id) { 
				    data[0][i]["value"]=val; 
					break; 
				}
			}
			
		});
		
		radar.updateBlob(data);
	},
	
	wrap: function(text, width) {
		
		text.each(function() {
			var text = d3.select(this),
				words = text.text().split(/\s+/).reverse(),
				word,
				line = [],
				lineNumber = 0,
				lineHeight = 1.15, // ems
				y = text.attr("y"),
				x = text.attr("x"),
				dy = parseFloat(text.attr("dy")),
				tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");
			
			while (word = words.pop()) {
				line.push(word);
				tspan.text(line.join(" "));
				if ( (tspan.node().getComputedTextLength() > width) && (line.length > 1)) {
					line.pop();
					tspan.text(line.join(" "));
					line = [word];
					tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
				}
			}
		});
	},
	
	resize: function() {
		radar.isMobile.init();
		radar.isTablet.init();

		if (this.isMobile.flag) {
			this.setMobileVar();
			$('#form--trigger').prop('checked', false);
		} else {
			this.setDesktopVar();
			
			if (this.isTablet.flag) {
				$('#form--trigger').prop('checked', false);
			} else {
				$('#form--trigger').prop('checked', true);
			}
		}
		
		this.chart("#radar");
		this.blob();
	}	
	
}

$(document).ready(
	radar.init()
);


$(window).resize(function(){
	clearTimeout(radar.timer);
	setTimeout(radar.resize(), 500);
});

